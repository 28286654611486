import React, { useState, useEffect } from "react"
import { graphql, navigate } from 'gatsby'
import Helmet from 'react-helmet'
import HeadingBar from './../components/HeadingBar'
import Footer from './../components/Footer'

import { StaticImage } from 'gatsby-plugin-image'

import TYS from '../../static/assets/Tell Your Sons.png'

import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

SwiperCore.use([Pagination]);


const Index = () => {

  return (
    <>
      <Helmet>
        <title>Tell Your Sons - A Podcast with George 'Monk' Foreman III</title>
      </Helmet>
      <div className="home">
        <img
          src={TYS}
          alt="Tell Your Sons Podcast"
        />
        <div className="player">
          <h6>Tell your sons about it,<br />
            And let your sons tell their sons,<br />
            And their sons the next generation.
            <br /><br />
            Joel 1:3
          </h6>
          <p>George 'Monk' Foreman III grew up at the hip of his father - heavyweight champion, gold medalist and legendary businessman George Foreman. 
          His Dad always taught him life’s most 
          important lessons through stories. As Monk started building his own career in boxing and business he came across many
          young men who didn't have the same experience with their fathers growing up. He decided to start recording short conversations with men he looks up to -
          telling simple stories that the younger generation can draw wisdom and inspiration from when faced with life's most 
          pivotal challenges. That's how the podcast Tell Your Sons was born.</p>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Index