import React from "react"
import { Link } from 'gatsby'

import Twitter from '../../static/assets/Twitter.png'
import Instagram from '../../static/assets/Instagram.png'
import Youtube from '../../static/assets/Youtube.png'
import TikTok from '../../static/assets/TikTok.png'

export default () => (
     <footer>
     	<div className="social-icons">
     		<a href="https://www.twitter.com/TellYourSonsPod" target="_blank"><img src={Twitter} alt="Twitter" /></a>
     		<a href="https://www.instagram.com/tellyoursons" target="_blank"><img src={Instagram} alt="Instagram" /></a>
     		<a href="https://www.tiktok.com/@tellyoursons" target="_blank"><img src={TikTok} alt="TikTok" /></a>
     		<a href="https://www.youtube.com/channel/tellyoursons" target="_blank"><img src={Youtube} alt="Youtube" /></a>
     	</div>
     	<p>©JR3 Media Inc. All Rights Reserved.</p>
     </footer>
  
)